import { env } from '$env/dynamic/public';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: env.PUBLIC_SENTRY_DSN,
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
	tracesSampleRate: 1.0,
	ignoreErrors: [/Failed to execute 'getComputedStyle' on 'Window'/],
});

Sentry.setTag('svelteKit', 'browser');

// This will catch errors in load functions from +page.ts files
export const handleError = Sentry.handleErrorWithSentry((event: unknown) => {
	console.error(event);
});
